export class ProductDocument {
    public bindingTypeName:string;
    public countryName:string;
    public documentId: number;
    public documentName: string;
    public documentTypeName: string;
    public localizedLanguageName: string;
    public number: string;
    public pagesCount: number;
    public productName: string;
    public productSubTypeName: string;
    public producttypeName: string;
    public quantity: number
    public status:string;
}