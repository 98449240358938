import { Country } from "./country";

export class Address {
    public name:string;
    public addressline1:string;
    public addressline2: string;
    public country: Country;
    public state: string;
    public city: string;
    public postalcode: string;
    public phone: string;
    public email: string;
    public specialinstructions: string;
    public resdelivery: boolean;
}
